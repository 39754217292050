// @flow
import * as React from "react";
import StepContainer from "../container/StepContainer";
import image from "../assets/images/notes_cancel.svg";
import {Trans} from "react-i18next";
import type {translate} from "../i18n/i18n";
import "./CancelPage.scss";

type Props = {
	handleBack: () => void,
	baseUrl: string,
	t: translate
};

export const CancelPage = (props: Props) => {
	return <StepContainer>
		<div id={"cancelPage"}>
			<p><img className={"cancelBanner"} src={image} alt={"Canceled"}/></p>
			<p className="center"><strong>{props.t("order.cancel.headlineSub")}</strong></p>
			<p className="center">
				<Trans i18nKey="order.cancel.text">
					Either the reservation time expired or it was canceled manually.<br/>
					If you payed a reservation-fee it will be fully refunded.
				</Trans>
			</p>
			<p className="center">
				<a className={"btn"} href={props.baseUrl}>{props.t("order.cancel.button")}</a>
			</p>
		</div>
	</StepContainer>;
};
