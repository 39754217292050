// @flow
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import AppContainer from "./container/AppContainer";
import "./i18n/i18n.js";
import BrowserNotSupported from "./helper/BrowserNotSupported/BrowserNotSupported";
import RootNodeProvider from "./helper/RootNodeProvider";

const isInternetExplorer = !!document.documentMode;
const root = RootNodeProvider.getRpmRootNode();

if (root) {
	ReactDOM.render(
		<Root>
			{isInternetExplorer
				? <BrowserNotSupported/>
				: <AppContainer/>
			}
		</Root>,
		root
	);
}
