// @flow
import React from "react";
import {customHistory} from "../helper/history";
import {Route, Router} from "react-router";
import CartPageContainer from "../container/CartPageContainer";
import AddressPageContainer from "../container/AddressPageContainer";
import SummaryPageContainer from "../container/SummaryPageContainer";
import ConfirmationPageContainer from "../container/ConfirmationPageContainer";
import StepperContainer from "../container/StepperContainer";
import CounterContainer from "../container/CounterContainer";
import VehicleDisplayContainer from "../container/VehicleDisplayContainer";
import "./Content.scss";
import OrderPageContainer from "../container/OrderPageContainer";
import {Translation, withTranslation} from "react-i18next";
import LegalModalContainer from "../container/LegalModalContainer";
import ReservationModalContainer from "../container/ReservationModalContainer";
import CancelationModalContainer from "../container/ConfirmCancelModalContainer";
import PaymentPendingPageContainer from "../container/PaymentPendingPageContainer";
import PaymentErrorPageContainer from "../container/PaymentErrorPageContainer";
import {Loading} from "./Loading";
import CarNotAvailableContainer from "../container/CarNotAvailableContainer";
import OfferCounterContainer from "../container/OfferCounterContainer";
import {RESERVATION_STATE} from "../actions/reservation";

type ContentProps = {
	showFinished: boolean,
	isIdle: boolean,
	printBannerUrl: string,
	t: Translation,
	carNotAvailable: boolean,
	reservationState: string,
	isSale: boolean,
};

export const Content = (props: ContentProps) => {
	if (!props.isIdle) {
		return <React.Fragment>
			{props.isSale
				? <h3>{props.t("system.carSale")}</h3>
				: <h3>{props.t("system.carReservation")}</h3>
			}
			<br/>
			<VehicleDisplayContainer/>
			<div className={"Content"}>
				<Loading>{props.t("system.loading")}</Loading>
			</div>
		</React.Fragment>;
	}

	if (props.carNotAvailable) {
		return <React.Fragment>
			<div className={"Content CarNotAvailable"}>
				<CarNotAvailableContainer/>
			</div>
		</React.Fragment>;
	}

	if (props.showFinished) {
		const headline = () => {
			switch (props.reservationState) {
				case RESERVATION_STATE.CANCELED:
				case RESERVATION_STATE.RETIRED:
					return props.t("order.cancel.headline");
				default:
					return 	props.isSale
						? props.t("orderPage.headline.sale")
						: props.t("orderPage.headline.reservation");
			}
		};
		return <React.Fragment>
			<ReservationModalContainer/>
			<CancelationModalContainer/>
			<h3>{headline()}</h3>
			{!props.isSale && <OfferCounterContainer/>}
			<br/>
			<VehicleDisplayContainer/>
			<div className={"Content"}>
				<OrderPageContainer/>
			</div>
		</React.Fragment>;
	}

	return <Router history={customHistory}>
		<LegalModalContainer/>
		{props.isSale
			? <h3>{props.t("system.carSale")}</h3>
			: <h3>{props.t("system.carReservation")}</h3>
		}
		<CounterContainer/>
		<VehicleDisplayContainer/>
		<StepperContainer/>
		<div className={"Content"}>
			<Route exact path="/" component={CartPageContainer}/>
			<Route exact path="/address" component={AddressPageContainer}/>
			<Route exact path="/payment" component={PaymentPendingPageContainer}/>
			<Route exact path="/failure" component={PaymentErrorPageContainer}/>
			<Route exact path="/summary" component={SummaryPageContainer}/>
			<Route exact path="/confirmation" component={ConfirmationPageContainer}/>
		</div>
	</Router>;
};

export default withTranslation()(Content);
