// @flow
import React from "react";
import "./VehicleDisplay.scss";
import {Translation} from "react-i18next";
import type {DealerDetails, VehicleOption} from "../helper/ReservationPaymentApi";

type VehicleDisplayProps = {
	imageUrl: string,
	name: string,
	description: ?string,
	price: string,
	discountedPrice: ?string,
	t: Translation,
	dealerDetails: ?DealerDetails,
	color: ?string,
	interior: ?string,
	vehicleOptions: ?VehicleOption[],
};

export const VehicleDisplay = (props: VehicleDisplayProps) => {
	const renderPrice = () => {
		return (!props.discountedPrice)
			? <div><span>{props.price}</span></div>
			: <div>
				<span>{props.price}*</span>
				<span>{props.discountedPrice}</span>
				<span><small>{props.t("vehiclePage.includingVat")}</small></span>
			</div>;
	};

	const renderDescription = () => {
		return (!props.description)
			? <div>
				<p><strong>{props.name}</strong></p>
				<p>{props.description}</p>
			</div>
			: <div>
				<p><strong>{props.name}</strong></p>
			</div>;
	};

	const renderDealerData = () => {
		return props.dealerDetails
			? <div className={"dealer"}>
				<p>{props.dealerDetails.companyName}, <br/> {props.dealerDetails.address.zip} {props.dealerDetails.address.city}</p>
			</div>
			: null;
	};

	const renderColors = () => {
		return props.color && props.interior
			? <div className={"vehicleColors"}>
				<ul><li>{props.color}</li><li>{props.interior}</li></ul>
			</div>
			: null;
	};

	const renderOptions = () => {
		return props.vehicleOptions
			? <div className={"vehicleOptions"}>
				<ul>
					{props.vehicleOptions.map(option => <li key={option.label}>{option.label}</li>)}
				</ul>
			</div>
			: null;
	};

	return (
		<div className="vehicleDisplay">
			<div>
				<img src={props.imageUrl} alt={""}/>
			</div>
			<div>
				{renderDescription()}
				{renderDealerData()}
				{renderPrice()}
				{renderOptions()}
				{renderColors()}
			</div>
		</div>
	);
};
