import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {PaymentFacility} from "../model/PaymentFacility";

export type paymentFacilitiesResponse = {
	paymentFacilities: PaymentFacility[]
}
export type PaymentMethod = {
	paymentMethod: string,
	paymentProvider: string,
};
export type PaymentMethodResponse = {
	paymentMethods: PaymentMethod[],
};
export type PaymentFacilitySessionResponse = {
	session: {
		paymentProvider: { code: string },
		account: {
			url: ?string, // psa account
			checkoutSessionUrl: ?string, // saleNZ account
			parameters: string,
			method: string,
			psaId: string,
		}
	},
};

export default class PaymentApi {
	api: Axios;

	constructor(language: string = "en") {
		const baseUrl = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split("/")[1];
		this.api = axios.create({
			baseURL: `${Configuration.value("paymentApiBaseUrl")}`,
			headers: {
				"Content-Type": "application/json",
				"X-PSA-BROWSER-PAGE-BASEURL": baseUrl,
				"X-PSA-BROWSER-LANGUAGE": language,
				"X-PSA-BROWSER-JAVASCRIPT-ENABLED": true,
				"X-PSA-BROWSER-JAVA-ENABLED": navigator.javaEnabled(),
				"X-PSA-BROWSER-COLOR-DEPTH": window.screen.colorDepth,
				"X-PSA-BROWSER-SCREEN-HEIGHT": window.screen.height,
				"X-PSA-BROWSER-SCREEN-WIDTH": window.screen.width,
				"X-PSA-BROWSER-TIMEZONE": new Date().getTimezoneOffset()
			},
		});
	}

	// DEPRACED
	async getPaymentFacilities(orderId: string): Promise<paymentFacilitiesResponse> {
		return this.api.get(`payment/facilities/order/${orderId}`)
			.then(response => response.data);
	};

	getPaymentMethods = async (orderNo: string, marketNo: string): Promise<PaymentFacilitySessionResponse> => {
		return this.api.get(`payment/methods/dealer/${marketNo}`)
			.then(response => {
				const paymentMethod: PaymentMethod = response.data.paymentMethods[0];
				const body = {
					paymentProvider: paymentMethod.paymentProvider.toUpperCase(),
					paymentMethod: paymentMethod.paymentMethod.toUpperCase(),
				};
				return this.assignPaymentForOrder(orderNo, body)
					.then(res => {
						return res;
					})
					.catch(err => {
						/* eslint-disable-next-line */
						console.error("Error on assign payment", err);
					});
			})
			.catch(err => {
				/* eslint-disable-next-line */
				console.error("Error on loading paymentMethods", err);
			});
	};

	assignPaymentForOrder = async (orderNo: string, method: PaymentMethod): Promise<PaymentFacilitySessionResponse> => {
		return this.api.post(`payment/provider/assign/${orderNo}`, method)
			.then(res => {
				return res.data;
			});
	};
}
