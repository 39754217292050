// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {SummaryPage} from "../components/SummaryPage";
import {withTranslation} from "react-i18next";
import {toggleTermsAndConditions} from "../actions/navigation";
import {completePurchase} from "../actions/checkout";
import currencyConverter from "../helper/currency";
import {toLocaleDate} from "../helper/dateFormatter";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		customerData: state.customerData,
		selectedPaymentMethod: "Online Payment",
		vehicle: state.content.vehicleDetails,
		reservationPrice: currencyConverter(state.content.reservationFee, state.config.language, false),
		expiresAt: toLocaleDate(state.content.expiresAt),
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		handleSubmit: () => {
			dispatch(completePurchase());
			dispatch({type: "CHECKOUT_COMPLETE"});
		},
		toggleTermsAndConditions: () => dispatch(toggleTermsAndConditions()),
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SummaryPage));
