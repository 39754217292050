// @flow

import type {Price} from "./ReservationPaymentApi";

const getFormat = (priceValue: number, hideFractions: boolean) => {
	return (hideFractions)
		? {
			...{
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
			},
			...removeFractionDigitsForEdge(priceValue)
		}
		: {
			...{
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			},
		};
};

const removeFractionDigitsForEdge = (priceValue: number) => {
	const significantPriceDigits = Math.round(priceValue).toString().length;
	return {maximumSignificantDigits: significantPriceDigits};
};

const currencyConverter = (price: Price, lang: string = "de", hideFractions: boolean = false) => {
	return new Intl.NumberFormat(lang, {
		...getFormat(price.value, hideFractions),
		...{style: "currency", currency: price.currencyCode}
	}).format(price.value);
};

export default currencyConverter;
