// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import {ReservationPrice} from "../components/ReservationPrice";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		price: state.content.reservationFee,
		lang: state.config.language
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ReservationPrice));
