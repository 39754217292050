// @flow
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import {Provider} from "react-redux";
import defaultStore from "./store";
import LangSwitchProvider from "./i18n/LangSwitchProvider";

const Root = ({children}: any) => {
	return (
		<Provider store={defaultStore}>
			<LangSwitchProvider>
				{children}
			</LangSwitchProvider>
		</Provider>
	);
};

export default Root;
