// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import {PriceDisplay} from "../components/PriceDisplay";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		price: state.content.vehicleDetails.price,
		lang: state.config.language
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PriceDisplay));
