// @flow
import React from "react";
import currencyConverter from "../helper/currency";
import type {Price} from "../helper/ReservationPaymentApi";

type Props = {
	price: ?Price,
	lang: string,
};
export const ReservationPrice = (props: Props) => {
	if (props.price) return <React.Fragment>{currencyConverter(props.price, props.lang, false)}</React.Fragment>;
	return null;
};
