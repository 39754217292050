// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import Counter from "../components/Counter";
import {withTranslation} from "react-i18next";
import {RESERVATION_STATE} from "../actions/reservation";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		expiresAt: state.content.expiresAt,
		showCounter: ![RESERVATION_STATE.CANCELED, RESERVATION_STATE.RETIRED].includes(state.content.reservationState),
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Counter));
