import type {ReducerAction} from "./index";
import {SET_ADDRESS} from "../actions/checkout";
import {RESERVATION} from "../actions/reservation";

export type CustomerState = {
	emailAddress: string,
	firstname: string,
	lastname: string,
	phoneNumber: string,
	isTncAccepted: boolean
};

const initialState: CustomerState = {
	emailAddress: "",
	firstname: "",
	lastname: "",
	phoneNumber: "",
	isTncAccepted: false
};

export const customerReducer = (state: CustomerState = initialState, action: ReducerAction): CustomerState => {
	switch (action.type) {
		case SET_ADDRESS.FIRSTNAME: {
			return {
				...state,
				firstname: action.payload
			};
		}
		case SET_ADDRESS.LASTNAME: {
			return {
				...state,
				lastname: action.payload
			};
		}
		case SET_ADDRESS.PHONENUMBER: {
			return {
				...state,
				phoneNumber: action.payload
			};
		}
		case SET_ADDRESS.EMAIL: {
			return {
				...state,
				emailAddress: action.payload
			};
		}
		case SET_ADDRESS.TNC: {
			return {
				...state,
				isTncAccepted: !state.isTncAccepted
			};
		}
		case RESERVATION.GET.SUCCESS: {
			return {
				...state,
				firstname: action.payload.customer.name.firstname,
				lastname: action.payload.customer.name.lastname,
				phoneNumber: action.payload.customer.phone.number,
				emailAddress: action.payload.customer.emailAddress.address,
			};
		}
		default:
			return state;
	}
};
