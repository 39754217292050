/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import StepContainer from "../container/StepContainer";
import {Translation} from "react-i18next";
import type {CustomerData, VehicleDetails} from "../helper/ReservationPaymentApi";
import VehicleOptionsPriceContainer from "../container/VehicleOptionsPriceContainer";
import ReservationPriceContainer from "../container/ReservationPriceContainer";
import "./SummaryPage.scss";

type SummaryPageProps = {
	handleSubmit: () => void,
	toggleTermsAndConditions: () => void,
	t: Translation,
	vehicle: VehicleDetails,
	reservationPrice: string,
	expiresAt: string,
	selectedPaymentMethod: string,
	customerData: CustomerData
};
export const SummaryPage = (props: SummaryPageProps) => {
	const renderDetails = () => {
		return <div>
			<h4>{props.t("summary.title")}</h4>
			<table className={"summary"}>
				<tbody>
					<tr>
						<td colSpan="2">
							{props.vehicle.model}<span/><br/>
							<span><small>{props.t("cart.vehicleNumber")} {props.vehicle.externalId.identifier}</small></span>
						</td>
					</tr>
					<tr className="mlo">
						<td>{props.t("orderPage.externalColor")}</td>
						<td>{props.vehicle.externalColor}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.internalColor")}</td>
						<td>{props.vehicle.internalColor}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.options")}</td>
						<td>
							<ul>
								{props.vehicle.options.map(option => <li key={option.label}>{option.label}</li>)}
							</ul>
						</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.optionsPrice")}</td>
						<td><VehicleOptionsPriceContainer/></td>
					</tr>
					<tr>
						<td colSpan={"2"}>
							<strong>{props.t("orderPage.yourOrder")}</strong>
						</td>
					</tr>
					<tr className="mlo">
						<td>{props.t("orderPage.firstName")}</td>
						<td>{props.customerData.firstname}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.lastName")}</td>
						<td>{props.customerData.lastname}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.phoneNumber")}</td>
						<td>{props.customerData.phoneNumber}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.email")}</td>
						<td>{props.customerData.emailAddress}</td>
					</tr>
					<tr className="mlo">
						<td>{props.t("summary.paymentMethod")}</td>
						<td>{props.selectedPaymentMethod}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.reservationFee")}</td>
						<td><ReservationPriceContainer/></td>
					</tr>
				</tbody>
			</table>
		</div>;
	};

	const renderPriceBox = () => {
		return <div>
			<button onClick={() => props.handleSubmit()} className={"btn"}>
				{props.t("priceBox.orderNow")} <br/>
			</button>
		</div>;
	};

	return (
		<StepContainer>
			{renderDetails()}
			{renderPriceBox()}
		</StepContainer>
	);
};
