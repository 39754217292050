// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import {PaymentPendingPage} from "../components/PaymentPendingPage";
import {customHistory} from "../helper/history";
import {initiatePayment} from "../actions/checkout";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		reservationState: state.content.reservationState,
		paymentFacilities: state.content.paymentFacilities || [],
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		onLoad: () => dispatch(initiatePayment()),
		handleSuccess: () => {
			dispatch({type: "NAV_TO_SUMMARY"});
			customHistory.push("/summary");
		},
		handleFailure: () => {
			dispatch({type: "NAV_TO_PAYMENT_FAILURE"});
			customHistory.push("/failure");
		},
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PaymentPendingPage));
