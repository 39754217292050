// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {Step} from "../components/Step";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		isIdle: true
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Step));
