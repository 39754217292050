// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {ConfirmCancelModal} from "../components/ConfirmCancelModal";
import {closeModal} from "../actions/navigation";
import {withTranslation} from "react-i18next";
import {cancelReservation} from "../actions/checkout";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		showModal: state.content.showCancelationModal
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		handleCancelReservation: () => dispatch(cancelReservation()),
		handleAbort: () => dispatch(closeModal())
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ConfirmCancelModal));
