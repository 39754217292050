// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {Stepper} from "../components/Stepper";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";

type StepperContainerProps = {}

function mapStateToProps(state: ApplicationState, ownProps: StepperContainerProps) {
	return {};
}

function mapDispatchToProps(dispatch, ownProps: StepperContainerProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(withRouter(Stepper)));
