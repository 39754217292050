// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {CartPage} from "../components/CartPage";
import {customHistory} from "../helper/history";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		name: state.content.vehicleDetails.model,
		vehicleId: state.content.vehicleDetails.externalId.identifier
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		handleSubmit: () => {
			dispatch({type: "NAV_TO_ADDRESS"});
			customHistory.push("/address");
		}
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(CartPage));
