// @flow
import * as React from "react";
import "./Loading.scss";
import Spinner from "react-spinkit";

type Props = {
	children: React.Node
};
export const Loading = (props: Props) => {
	return <div className={"Loading"}>
		<Spinner name="ball-beat"/>
	</div>;
};
