/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import "./ReservationModal.scss";
import type {translate} from "../i18n/i18n";
import {Trans} from "react-i18next";

type ReservationModalProps = {
	showModal: boolean,
	closeModal: () => void,
	t: translate
};
export const ReservationModal = (props: ReservationModalProps) => {
	return props.showModal
		? (
			<div className={"ReservationModal"}>
				<div>
					<div>
						<p className="center">
							<Trans i18nKey="reservationModal.description">
								Merci, votre adresse e-mail a été vérifiée et votre commande bien prise en compte.<br/>
								Un nouvel e-mail vient de vous être envoyé avec le détail de votre commande.
							</Trans>
						</p>
						<p className="center">
							<a onClick={() => props.closeModal()} className="btn">{props.t("reservationModal.continue")}</a>
						</p>
					</div>
				</div>
			</div>
		) : null;
};
