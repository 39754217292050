// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {LegalModal} from "../components/LegalModal";
import {closeModal} from "../actions/navigation";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		showPrivacyPolicy: state.content.showPrivacyPolicy,
		showTermsAndConditions: state.content.showTermsAndConditions,
		termsAndConditions: state.content.market.legalTerms,
		privacyPolicy: state.content.market.dataPrivacy,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		closeModal: () => dispatch(closeModal())
	};
}

export default connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(LegalModal);
