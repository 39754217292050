// @flow
import React, {useEffect} from "react";
import "./App.scss";
import i18next from "i18next";
import {Translation} from "react-i18next";
import {CitroenTheme, DSTheme, OpelTheme, PeugeotTheme, NoTheme} from "../themes/PSA";
import ContentContainer from "../container/ContentContainer";
import {Loading} from "./Loading";

type AppProps = {
	configLoaded: boolean,
	t: Translation,
	initModule: () => void,
	manufacturer: string,
}

const App = (props: AppProps) => {
	const i18nStyle = i18next.language === "are" ? "rtl" : "default";
	useEffect(() => {
		props.initModule();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderApp = () => <div className={i18nStyle} id="vehicle-checkout-app">
		{
			(props.configLoaded)
				? <ContentContainer/>
				: <Loading>{props.t("system.loading")}</Loading>
		}
	</div>;

	switch (props.manufacturer) {
		case "DS":
			return <DSTheme>{renderApp()}</DSTheme>;
		case "Opel":
			return <OpelTheme>{renderApp()}</OpelTheme>;
		case "Peugeot":
			return <PeugeotTheme>{renderApp()}</PeugeotTheme>;
		case "Citroen":
			return <CitroenTheme>{renderApp()}</CitroenTheme>;
		default:
			return <NoTheme>{renderApp()}</NoTheme>;
	}
};

export default App;
