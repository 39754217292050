/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React from "react";
import image from "../assets/images/hourglass_top.svg";
import StepContainer from "../container/StepContainer";
import {Trans, Translation} from "react-i18next";
import {AUTHORIZATION_STATE} from "../helper/ReservationPaymentApi";
import type {PaymentFacility} from "../model/PaymentFacility";
import "./PaymentPendingPage.scss";

type PaymentPendingPageProps = {
    t: Translation,
    onLoad: () => void,
    handleSuccess: () => void,
    handleFailure: () => void,
    reservationState: ?string,
    paymentFacilities: PaymentFacility[],
};

export const PaymentPendingPage = (props: PaymentPendingPageProps) => {
	React.useEffect(() => {
		props.onLoad();
		if (props.paymentFacilities && props.paymentFacilities.length > 0) {
			window.open(props.paymentFacilities[0].account.url ? encodeURI(props.paymentFacilities[0].account.url || "") : props.paymentFacilities[0].account.checkoutSessionUrl,
				"_blank",
				"width=400,height=800"
			);
		}
	}, []);
	React.useEffect(() => {
		if (props.reservationState === AUTHORIZATION_STATE.authorized) {
			return props.handleSuccess();
		}
		if (props.reservationState === AUTHORIZATION_STATE.failed) {
			return props.handleFailure();
		}
	});

	return (
		<StepContainer>
			<div className={"paymentPendingPage"}>
				<div className="center">
					<p><img className={"confirmBanner"} src={image} alt={""}/></p>
					<p className={"center"}><strong>{props.t("payment.waiting")}</strong></p>
					<p>
						<Trans i18nKey="payment.text">
                            Please execute the payment in the newly opened window.<br/>
                            You will be redirected to this page after the payment is completed.
						</Trans>
					</p>
					<p>
						{(props.paymentFacilities).map(facility =>
							<a href={facility.account.url ? encodeURI(facility.account.url || "") : facility.account.checkoutSessionUrl}
							   target={"_blank"} key={facility.paymentProvider}>
								<i>➤ {props.t("confirmationPage.reload")} </i>
							</a>)}
					</p>
				</div>
			</div>
		</StepContainer>
	);
};
