exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/fonts/Opel-Next-Light.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/fonts/Opel-Next-Bold.woff2"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../assets/fonts/Opel-Next-Regular.woff2"));

// Module
exports.push([module.id, ":root {\n  --primary: #000;\n  --secondary: #000;\n  --primaryText: #FFF;\n  --primaryTextInverted: #000;\n  --secondaryText: #FFF;\n  --secondaryTextInverted: #000;\n  --border: rgba(208, 203, 203, 1.00);\n  --alert: rgba(229, 000, 000, 1.0); }\n\n#root .notAvailableButton {\n  color: white !important; }\n\n#root p > a,\n#root label > a {\n  color: black;\n  text-decoration: underline; }\n\n@font-face {\n  font-family: 'sofi-li';\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"); }\n\n@font-face {\n  font-family: 'sofi-bo';\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff2\"); }\n\n@font-face {\n  font-family: 'sofi-ex';\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff2\"); }\n", ""]);

