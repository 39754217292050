// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {AddressPage} from "../components/AddressPage";
import {withTranslation} from "react-i18next";
import {sendCustomerInformation, SET_ADDRESS} from "../actions/checkout";
import {togglePrivacyPolicy, toggleTermsAndConditions} from "../actions/navigation";
import {validateEmail} from "../helper/validateEmail";

function mapStateToProps(state: ApplicationState, ownProps) {
	const {emailAddress, firstname, lastname, phoneNumber, isTncAccepted} = state.customerData;

	return {
		emailAddress,
		firstname,
		lastname,
		phoneNumber,
		isTncAccepted,
		isIdle: state.content.isIdle,
		isAddressValid: emailAddress && validateEmail(emailAddress) && firstname && lastname && phoneNumber && isTncAccepted,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		handleSubmit: () => {
			dispatch({type: "NAV_TO_PAYMENT"});
			dispatch(sendCustomerInformation());
		},
		handleFirstNameChange: value => dispatch({type: SET_ADDRESS.FIRSTNAME, payload: value}),
		handleLastNameChange: value => dispatch({type: SET_ADDRESS.LASTNAME, payload: value}),
		handleMailChange: value => dispatch({type: SET_ADDRESS.EMAIL, payload: value}),
		handlePhoneChange: value => dispatch({type: SET_ADDRESS.PHONENUMBER, payload: value}),
		toggleAcceptTnc: () => dispatch({type: SET_ADDRESS.TNC}),
		toggleTermsAndConditions: () => dispatch(toggleTermsAndConditions()),
		togglePrivacyPolicy: () => dispatch(togglePrivacyPolicy())
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(AddressPage));
