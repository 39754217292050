import type {Tracking} from "./Tracking";

export const trackers: Tracking[] = [];

export const tracker = store => next => action => {
	try {
		const trackEvent = (actionType: string) => {
			const tracker = window.ga || document.ga;
			if (tracker) {
				tracker("send", "event", actionType, "Click");
			}
			trackers.forEach(tracker => tracker.handleGenericEvent("vcm", actionType, "Test"));
		};

		switch (action.type) {
			case "INITIATE_CHECKOUT_CAR_NOT_AVAILABLE": {
				trackEvent(action.type);
				break;
			}

			case "INITIATE_CHECKOUT_SUCCESS": {
				trackEvent(action.type);
				break;
			}

			case "INITIATE_CHECKOUT_SUCCESS_VCG": {
				trackEvent(action.type);
				break;
			}

			case "NAV_TO_ADDRESS": {
				trackEvent(action.type);
				break;
			}

			case "NAV_TO_PAYMENT": {
				trackEvent(action.type);
				break;
			}

			case "NAV_TO_SUMMARY": {
				trackEvent(action.type);
				break;
			}

			case "NAV_TO_PAYMENT_FAILURE": {
				trackEvent(action.type);
				break;
			}

			case "CHECKOUT_COMPLETE": {
				trackEvent(action.type);
				break;
			}

			default: {
				break;
			}
		}
	} catch (e) {
		console.error(e);
	}
	return next(action);
};
