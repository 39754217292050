// @flow

import ReservationPaymentApi from "../helper/ReservationPaymentApi";
import type {ApplicationState} from "../reducers";
import type {Dispatch} from "./index";
import {INITIATE_CHECKOUT} from "./checkout";
import type {Market} from "../model/Market";

export const RESERVATION = {
	GET: {
		REQUEST: "RESERVATION_GET_REQUEST",
		SUCCESS: "RESERVATION_GET_SUCCESS",
		FAILURE: "RESERVATION_GET_FAILURE"
	},
	CLOSE_MODAL: "RESERVATION_CLOSE_MODAL",
	CANCEL: "RESERVATION_CANCEL",
};

export const RESERVATION_STATE = {
	VEHICLE_BLOCKED: "vehicleBlocked",
	CUSTOMER_DATA_APPLIED: "customerDataApplied",
	PAYMENT_AUTHORIZED: "paymentAuthorized",
	PAYMENT_VERIFICATION_FAILURE: "paymentVerificationFailure",
	CUSTOMER_CONFIRMED: "customerConfirmed",
	EXPIRED: "expired",
	CANCELED: "canceled",
	CONFIRMED: "CONFIRMED",
	RETIRED: "RETIRED",
	NOT_CONFIRMED: "NOT_CONFIRMED",
};

export const getReservation = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: RESERVATION.GET.REQUEST
		});
		const reservationPaymentApi = new ReservationPaymentApi();
		return reservationPaymentApi.getReservation(getState().config.reservationNo)
			.then(reservationResponse => {
				reservationPaymentApi.getMarketDetails(reservationResponse.dealer.marketNo.index)
					.then(marketResponse => {
						dispatch({
							type: RESERVATION.GET.SUCCESS,
							payload: {
								...reservationResponse,
								market: {...marketResponse}
							}
						});
					})
					.catch(() => {
						dispatch({
							type: RESERVATION.GET.FAILURE
						});
					});
			})
			.catch(() => {
				dispatch({
					type: RESERVATION.GET.FAILURE
				});
			});
	};
};

export const getReservationDetails = (reservationNo: string, market: Market, windowExpiresAt: string, vcg: boolean, saleNz: boolean) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		const reservationPaymentApi = new ReservationPaymentApi();
		return reservationPaymentApi.getReservation(reservationNo)
			.then(res => {
				const type = vcg ? INITIATE_CHECKOUT.SUCCESS_VCG : (saleNz ? INITIATE_CHECKOUT.SUCCESS_SALE_NZ : INITIATE_CHECKOUT.SUCCESS);
				if (!res.dealer || !res.vehicle) {
					throw new Error("error");
				}
				dispatch({
					type,
					payload: {
						dealerDetails: res.dealer,
						vehicleDetails: res.vehicle,
						market: {...market, ...res.market},
						windowExpiresAt,
						expiresAt: res.expiresAt,
						reservationFee: res.reservationFee,
						reservationState: res.reservationState,
						reservationNo: res.reservationNo,
						verificationUntil: res.verificationUntil,
					}
				});
			})
			.catch(() => {
				setTimeout(() => dispatch(getReservationDetails(reservationNo, market, windowExpiresAt, vcg, saleNz)), 1000 * 5);
			});
	};
};
