// @flow
import React from "react";
import "./Step.scss";
import {Translation} from "react-i18next";
import {Loading} from "./Loading";

type StepProps = {
	children: any,
	isIdle: boolean,
	t: Translation,
};

export const Step = (props: StepProps) => {
	return props.isIdle
		? <div className={"Step"}>
			<div>
				{props.children}
			</div>
		</div>
		: <Loading>{props.t("system.loading")}</Loading>;
};
