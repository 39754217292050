// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {OrderPage} from "../components/OrderPage";
import {withTranslation} from "react-i18next";
import {toggleCancelationModal} from "../actions/navigation";
import {confirmReservation} from "../actions/checkout";
import {RESERVATION_STATE} from "../actions/reservation";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		name: state.content.vehicleDetails.model,
		vehicle: state.content.vehicleDetails,
		dealerDetails: state.content.dealerDetails,
		createdAt: state.content.createdAt,
		expiresAt: state.content.expiresAt,
		showContext: state.config.reservationInitial,
		customer: state.customerData,
		reservationState: state.content.reservationState,
		showCancelButton: !!state.config.cancellationCode,
		shouldConfirmReservation: ![RESERVATION_STATE.CONFIRMED, RESERVATION_STATE.CANCELED, RESERVATION_STATE.RETIRED].includes(state.content.reservationState) && !state.config.cancellationCode,
		selectedPaymentMethod: "Online Payment",
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		toggleCancelationModal: () => dispatch(toggleCancelationModal()),
		confirmReservation: () => dispatch(confirmReservation()),
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(OrderPage));
