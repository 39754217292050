/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import "./LegalModal.scss";

type LegalModalProps = {
	showPrivacyPolicy: boolean,
	showTermsAndConditions: boolean,
	termsAndConditions: string,
	privacyPolicy: string,
	closeModal: () => void
};
export const LegalModal = (props: LegalModalProps) => {
	const content = props.showPrivacyPolicy
		? props.privacyPolicy
		: props.showTermsAndConditions
			? props.termsAndConditions
			: null;

	return content
		? (
			<div className={"LegalModal"}>
				<div>
					<span className="control">
						<a className="ico close" onClick={() => props.closeModal()} title="Close">	</a>
					</span>
					<div dangerouslySetInnerHTML={{__html: content}}/>
				</div>
			</div>
		) : null;
};
