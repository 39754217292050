// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {ConfirmationPage} from "../components/ConfirmationPage";
import {withTranslation} from "react-i18next";
import {deactivateCounter} from "../actions/navigation";

function mapStateToProps(state: ApplicationState, ownProps) {
	const expiresInHours = Math.abs(Date.parse(state.content.verificationUntil) - new Date()) / 36e5;
	return {
		expiresInHours: Math.round(expiresInHours),
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		deactivateCounter: () => dispatch(deactivateCounter()),
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ConfirmationPage));
