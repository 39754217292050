import type {ReducerAction} from "./index";
import {
	CANCEL_RESERVATION,
	CONFIRM_RESERVATION,
	INITIATE_CHECKOUT,
	INITIATE_PAYMENT,
	SEND_CUSTOMER_INFORMATION
} from "../actions/checkout";
import type {DealerDetails, No, Price, VehicleDetails} from "../helper/ReservationPaymentApi";
import {COUNTER, TOGGLE_MODAL} from "../actions/navigation";
import {RESERVATION, RESERVATION_STATE} from "../actions/reservation";
import type {Market} from "../model/Market";
import type {PaymentFacility} from "../model/PaymentFacility";

export type ContentReducerState = {
	configLoaded: boolean,
	reservationNo: No,
	market: Market,
	expiresAt: string,
	windowExpiresAt: string,
	createdAt: string,
	reservationFee: ?Price,
	reservationState: ?string,
	manufacturer: ?string,
	vehicleDetails: VehicleDetails,
	dealerDetails: DealerDetails,
	showPrivacyPolicy: boolean,
	showTermsAndConditions: boolean,
	asyncRequestQueue: string[],
	showCancelationModal: boolean,
	paymentFacilities: PaymentFacility[],
	carNotAvailable: boolean,
	showReservationModal: boolean,
	verificationUntil: ?Date,
};

const initialState: ContentReducerState = {
	market: {
		dataPrivacy: "",
		legalTerms: "",
		stockMonitor: {
			address: ""
		},
		manufacturer: {
			name: ""
		},
	},
	configLoaded: false,
	reservationNo: null,
	vehicleDetails: {
		vehicleNo: {},
		price: {},
		externalDealerId: {},
		externalId: {},
		options: [],
		vehicleBasePrice: {},
		basePriceWithOptions: {},
		sumOptionsPrice: {},
		discountType1: {},
		discountType2: {},
	},
	dealerDetails: null,
	showPrivacyPolicy: false,
	showTermsAndConditions: false,
	asyncRequestQueue: [],
	showCancelationModal: false,
	expiresAt: "",
	windowExpiresAt: "",
	createdAt: "",
	reservationFee: null,
	reservationState: null,
	paymentFacilities: [],
	carNotAvailable: false,
	showReservationModal: false,
	verificationUntil: null,
};

export const contentReducer = (state: ContentReducerState = initialState, action: ReducerAction): ContentReducerState => {
	switch (action.type) {
		case TOGGLE_MODAL.PRIVACY_POLICY:
			return {
				...state,
				showTermsAndConditions: false,
				showPrivacyPolicy: !state.showPrivacyPolicy
			};
		case TOGGLE_MODAL.TERMS_AND_CONDITIONS:
			return {
				...state,
				showTermsAndConditions: !state.showTermsAndConditions,
				showPrivacyPolicy: false
			};
		case TOGGLE_MODAL.CANCELATION:
			return {
				...state,
				showCancelationModal: !state.showCancelationModal
			};
		case TOGGLE_MODAL.CLOSE:
			return {
				...state,
				showTermsAndConditions: false,
				showPrivacyPolicy: false,
				showCancelationModal: false
			};
		case TOGGLE_MODAL.RESERVATION_SUCCESS:
			return {
				...state,
				showReservationModal: false,
			};
		case INITIATE_CHECKOUT.REQUEST:
			return {
				...state,
				configLoaded: false,
				asyncRequestQueue: [...state.asyncRequestQueue, INITIATE_CHECKOUT.REQUEST]
			};
		case INITIATE_CHECKOUT.SUCCESS:
			return {
				...state,
				configLoaded: true,
				market: action.payload.market,
				reservationNo: action.payload.reservationNo,
				vehicleDetails: action.payload.vehicleDetails,
				dealerDetails: action.payload.dealerDetails,
				windowExpiresAt: action.payload.windowExpiresAt,
				expiresAt: action.payload.expiresAt,
				reservationFee: action.payload.reservationFee,
				reservationState: action.payload.reservationState,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== INITIATE_CHECKOUT.REQUEST)],
				verificationUntil: action.payload.verificationUntil
			};
		case INITIATE_CHECKOUT.SUCCESS_VCG:
			return {
				...state,
				configLoaded: true,
				market: action.payload.market,
				reservationNo: action.payload.reservationNo,
				vehicleDetails: action.payload.vehicleDetails,
				dealerDetails: action.payload.dealerDetails,
				windowExpiresAt: action.payload.windowExpiresAt,
				expiresAt: action.payload.expiresAt,
				reservationFee: action.payload.reservationFee,
				reservationState: action.payload.reservationState,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== INITIATE_CHECKOUT.REQUEST)],
				verificationUntil: action.payload.verificationUntil
			};
		case INITIATE_CHECKOUT.SUCCESS_SALE_NZ:
			return {
				...state,
				configLoaded: true,
				market: action.payload.market,
				reservationNo: action.payload.reservationNo,
				vehicleDetails: action.payload.vehicleDetails,
				dealerDetails: action.payload.dealerDetails,
				windowExpiresAt: action.payload.windowExpiresAt,
				expiresAt: action.payload.expiresAt,
				reservationFee: action.payload.reservationFee,
				reservationState: action.payload.reservationState,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== INITIATE_CHECKOUT.REQUEST)],
				verificationUntil: action.payload.verificationUntil
			};
		case INITIATE_CHECKOUT.FAILURE:
			return {
				...state,
				asyncRequestQueue: [...state.asyncRequestQueue, INITIATE_PAYMENT.REQUEST]
			};
		case INITIATE_CHECKOUT.CAR_NOT_AVAILABLE:
			return {
				...state,
				configLoaded: true,
				carNotAvailable: true,
				asyncRequestQueue: [...state.asyncRequestQueue
					.filter(queue => queue !== INITIATE_CHECKOUT.REQUEST)
					.filter(queue => queue !== SEND_CUSTOMER_INFORMATION.REQUEST)]
			};
		case INITIATE_PAYMENT.REQUEST:
			return {
				...state,
				reservationState: null,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== INITIATE_PAYMENT.REQUEST)]
			};
		case INITIATE_PAYMENT.SUCCESS:
			return {
				...state,
				reservationState: action.payload,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== INITIATE_PAYMENT.REQUEST)]
			};
		case INITIATE_PAYMENT.PENDING:
			return {
				...state,
				reservationState: action.payload,
			};
		case INITIATE_PAYMENT.ABORT:
		case INITIATE_PAYMENT.FAILURE:
			return {
				...state,
				reservationState: action.payload,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== INITIATE_PAYMENT.REQUEST)]
			};
		case RESERVATION.GET.REQUEST:
			return {
				...state,
				configLoaded: false,
				asyncRequestQueue: [...state.asyncRequestQueue, RESERVATION.GET.REQUEST]
			};
		case RESERVATION.GET.SUCCESS:
			return {
				...state,
				configLoaded: true,
				market: action.payload.market,
				reservationNo: action.payload.reservationNo,
				vehicleDetails: action.payload.vehicle,
				dealerDetails: action.payload.dealer,
				expiresAt: action.payload.expiresAt,
				createdAt: action.payload.createdAt,
				reservationState: action.payload.reservationState,
				reservationFee: action.payload.reservationFee,
				showReservationModal: action.payload.reservationState === RESERVATION_STATE.NOT_CONFIRMED ? true : state.showReservationModal,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== RESERVATION.GET.REQUEST)]
			};
		case RESERVATION.GET.FAILURE:
			return {
				...state,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== RESERVATION.GET.REQUEST)]
			};

		case SEND_CUSTOMER_INFORMATION.REQUEST:
			return {
				...state,
				asyncRequestQueue: [...state.asyncRequestQueue, SEND_CUSTOMER_INFORMATION.REQUEST]
			};
		case SEND_CUSTOMER_INFORMATION.SUCCESS:
			return {
				...state,
				paymentFacilities: action.payload,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== SEND_CUSTOMER_INFORMATION.REQUEST)]
			};
		case SEND_CUSTOMER_INFORMATION.FAILURE:
			return {
				...state,
				carNotAvailable: true,
				asyncRequestQueue: [...state.asyncRequestQueue.filter(queue => queue !== SEND_CUSTOMER_INFORMATION.REQUEST)]
			};
		case CANCEL_RESERVATION.SUCCESS:
			return {
				...state,
				reservationState: action.payload,
				showCancelationModal: false,
			};
		case CANCEL_RESERVATION.FAILURE:
			return {
				...state,
				showCancelationModal: false,
			};
		case CONFIRM_RESERVATION.SUCCESS:
			return {
				...state,
				reservationState: action.payload
			};
		case COUNTER.DEACTIVATE:
			return {
				...state,
				windowExpiresAt: null,
			};
		default:
			return state;
	}
};
