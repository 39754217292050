// @flow

import type {Dispatch} from "./index";

type ToggleModalActionTypes = {
	TERMS_AND_CONDITIONS: string,
	PRIVACY_POLICY: string,
	CANCELATION: string,
	CLOSE: string,
	RESERVATION_SUCCESS: string,
};

export const TOGGLE_MODAL: ToggleModalActionTypes = {
	TERMS_AND_CONDITIONS: "TOOGLE_MODAL_TERMS_AND_CONDITIONS",
	PRIVACY_POLICY: "TOGGLE_MODAL_PRIVACY_POLICY",
	CANCELATION: "TOGGLE_MODAL_CANCELATION",
	CLOSE: "TOGGLE_MODAL_CLOSE",
	RESERVATION_SUCCESS: "TOGGLE_MODAL_RESERVATION_SUCCESS",
};

export const COUNTER = {
	DEACTIVATE: "deactivate",
};

export const toggleTermsAndConditions = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_MODAL.TERMS_AND_CONDITIONS
		});
	};
};

export const toggleCancelationModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_MODAL.CANCELATION
		});
	};
};

export const togglePrivacyPolicy = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_MODAL.PRIVACY_POLICY
		});
	};
};

export const closeReservationSuccessModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_MODAL.RESERVATION_SUCCESS
		});
	};
};

export const closeModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_MODAL.CLOSE
		});
	};
};

export const deactivateCounter = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: COUNTER.DEACTIVATE
		});
	};
};
