// @flow
import React from "react";
import StepContainer from "../container/StepContainer";
import {Translation} from "react-i18next";
import type {DealerDetails, VehicleDetails} from "../helper/ReservationPaymentApi";
import ReservationPriceContainer from "../container/ReservationPriceContainer";
import type {CustomerState} from "../reducers/customerData";
import {RESERVATION_STATE} from "../actions/reservation";
import CancelPageContainer from "../container/CancelPageContainer";
import "./OrderPage.scss";
import VehicleOptionsPriceContainer from "../container/VehicleOptionsPriceContainer";

type OrderPageProps = {
    name: string,
    vehicle: VehicleDetails,
    t: Translation,
    dealerDetails: DealerDetails,
    createdAt: string,
    expiresAt: string,
    customer: CustomerState,
    reservationState: ?string,
    toggleCancelationModal: () => void,
    confirmReservation: () => void,
    showCancelButton: boolean,
	shouldConfirmReservation: boolean,
    selectedPaymentMethod: string,
};

export const OrderPage = (props: OrderPageProps) => {
	React.useEffect(() => {
		if (props.shouldConfirmReservation) {
			props.confirmReservation();
		}
	});

	const renderDetails = () => {
		return <div>
			<table className={"reservationSummary"}>
				<tbody>
					<tr>
						<td colSpan="2">
							<span>{props.name}</span><br/>
							<span><small>{props.t("orderPage.vehicleNumber")} {props.vehicle.externalId.identifier}</small></span>
						</td>
					</tr>
					<tr className="mlo">
						<td>{props.t("orderPage.externalColor")}</td>
						<td>{props.vehicle.externalColor}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.internalColor")}</td>
						<td>{props.vehicle.internalColor}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.options")}</td>
						<td>
							<ul>
								{props.vehicle.options.map(option => <li key={option.label}>{option.label}</li>)}
							</ul>
						</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.optionsPrice")}</td>
						<td><VehicleOptionsPriceContainer/></td>
					</tr>
					<tr className={"mlo"}>
						<td colSpan={"2"}>
							<strong>{props.t("orderPage.yourOrder")}</strong>
						</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.firstName")}</td>
						<td>{props.customer.firstname}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.lastName")}</td>
						<td>{props.customer.lastname}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.phoneNumber")}</td>
						<td>{props.customer.phoneNumber}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.email")}</td>
						<td>{props.customer.emailAddress}</td>
					</tr>
					<tr className="mlo">
						<td>{props.t("summary.paymentMethod")}</td>
						<td>{props.selectedPaymentMethod}</td>
					</tr>
					<tr>
						<td>{props.t("orderPage.reservationFee")}</td>
						<td><ReservationPriceContainer/></td>
					</tr>
					<tr className="mlo">
						<td colSpan="2">
							<strong>{props.t("orderPage.dealerInformation")}</strong>
						</td>
					</tr>
					<tr>
						<td colSpan={"2"}>
							<div className={"flexBox"}>
								<div className={"dealerInfos"}>
									<table className={"innerTable"}>
										<tbody>
											<tr>
												<td rowSpan={3}>
													{props.dealerDetails.companyName} <br/>
													{props.dealerDetails.address.street}<br/>
													{props.dealerDetails.address.street2}<br/>
													{props.dealerDetails.address.zip} {props.dealerDetails.address.city}<br/>
													{props.dealerDetails.address.country}<br/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className={"dealerInfos"}>
									<table className={"innerTable"}>
										<tbody>
											{!!props.dealerDetails.contact.homepageUrl && <tr>
												<td><a className="link web"
													href={`https://${props.dealerDetails.contact.homepageUrl}`}>{props.dealerDetails.contact.homepageUrl}</a>
												</td>
											</tr>}
											{!!props.dealerDetails.contact.mail && <tr>
												<td><a className="link mail"
													href={`mailto:${props.dealerDetails.contact.mail}`}>{props.dealerDetails.contact.mail}</a>
												</td>
											</tr>}
											{!!props.dealerDetails.contact.phone && <tr>
												<td><a className="link tel"
													href={`tel:${props.dealerDetails.contact.phone.replace(" ", "")}`}>{props.dealerDetails.contact.phone}</a>
												</td>
											</tr>}
										</tbody>
									</table>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>;
	};

	const renderPriceBox = () => {
		return props.showCancelButton && <div className={"noPrint"}>
			<h4>{props.t("priceBox.reservationOptions")}</h4>
			<button onClick={() => props.toggleCancelationModal()}
				className={"btn"}>{props.t("priceBox.cancelReservation")}
			</button>
		</div>;
	};

	if (props.reservationState === RESERVATION_STATE.CANCELED || props.reservationState === RESERVATION_STATE.RETIRED) {
		return <CancelPageContainer/>;
	}

	return (
		<StepContainer>
			{renderDetails()}
			{renderPriceBox()}
		</StepContainer>
	);
};
