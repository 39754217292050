// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {Market} from "../model/Market";
import type {GetMarketResponse} from "../model/GetMarketResponse";
import type {Customer} from "../model/Customer";

export type Id = {
	identifier: string
};

export type No = {
	index: string
};

export type Code = {
	code: string
}

export type Price = {
	currency: Code,
	value: number,
	currencyCode: string,
}

export type VehicleOption = {
	category: string,
	label: string,
}

export type VehicleDetails = {
	vehicleNo: Id,
	model: string,
	price: Price, // price = vehicleBasePrice + options - discount
	imageUrl: string,
	externalDealerId: Id,
	externalId: Id,
	options: VehicleOption[],
	vinNumber: string,
	vehicleBasePrice: Price,
	basePriceWithOptions: Price,
	sumOptionsPrice: Price,
	discountType1: Price,
	discountType2: Price,
	externalColor: string,
	internalColor: string,
}

export type DealerAddress = {
	street: string,
	street2: string,
	city: string,
	zip: string,
	country: string,
	countryCode: string
}

export type DealerContact = {
	phone: string,
	mail: string,
	homepageUrl: string,
	salesPhone: string,
}

export type DealerDetails = {
	externalDealerId: Id,
	companyName: string,
	address: DealerAddress,
	contact: DealerContact,
	marketNo: No,
	vatNumber: string,
	siretNumber: string,
}

export type CustomerData = {
	emailAddress: string,
	firstname: string,
	lastname: string,
	phoneNumber: string,
	reservationNo: No
}

export type instantiateReservationProcessResponse = {
	reservationNo: No,
	expiresAt: string,
	vehicleDetails: VehicleDetails,
	dealerDetails: DealerDetails,
	market: Market
}

export type getReservationResponse = {
	reservationNo: No,
	vehicle: VehicleDetails,
	dealer: DealerDetails,
	expiresAt: string,
	reservationFee: string,
	reservationState: string,
	customer: Customer,
	verificationUntil: Date,
	market: Market,
}

export const AUTHORIZATION_STATE = {
	processing: "processing",
	authorized: "authorized",
	failed: "failed"
};

export type pollForPaymentResponse = {
	authorizationState: string,
}

export default class ReservationPaymentApi {
	api: Axios;

	constructor() {
		this.api = axios.create({
			baseURL: `${Configuration.value("reservationPaymentApiBaseUrl")}`,
			headers: {
				"Content-Type": "application/json"
			},
		});
	}

	async approveReservation(reservationNo: string): Promise<any> {
		return this.api.post(`reservation-payment/approve/${reservationNo}`, {queryParams: window.location.search})
			.then(response => response.data);
	}

	async confirmReservation(reservationNo: string): Promise<any> {
		return this.api.post(`reservation-payment/confirm/${reservationNo}`, {queryParams: window.location.search})
			.then(response => response.data);
	}

	async cancelReservation(reservationNo: string, cancellationCode: string): Promise<any> {
		const body = {
			reservationNo: {index: reservationNo},
			cancellationCode
		};
		return this.api.post("reservation-payment/cancel", body)
			.then(response => response.data);
	}

	async getReservation(reservationNo: string): Promise<getReservationResponse> {
		return this.api.get(`reservation-payment/${reservationNo}`)
			.then(response => response.data);
	}

	async getMarketDetails(marketNo: string): Promise<GetMarketResponse> {
		return this.api.get(`reservation-payment/market/${marketNo}`)
			.then(response => response.data);
	}

	async pollForPaymentFinished(reservationNo: string): Promise<pollForPaymentResponse> {
		return this.api.get(`reservation-payment/poll/authorization/${reservationNo}`)
			.then(response => response.data);
	}

	async instantiateSaleNzProcess(vehicleNo: Id, stockmonitorBaseUrl: string, dealerId?: Id, dealerLocationId?: Id): Promise<instantiateReservationProcessResponse> {
		const body = {
			dealerId,
			dealerLocationId,
			vehicleNo,
			stockmonitorBaseUrl
		};
		return this.api.post("reservation-payment/sale-nz/instantiate", body)
			.then(response => response.data);
	}

	async instantiateVCGSaleProcess(vehicleNo: Id, stockmonitorBaseUrl: string, dealerId?: Id, dealerLocationId?: Id): Promise<instantiateReservationProcessResponse> {
		const body = {
			dealerId,
			dealerLocationId,
			vehicleNo,
			stockmonitorBaseUrl
		};
		return this.api.post("reservation-payment/sale/instantiate", body)
			.then(response => response.data);
	}

	async instantiateReservationProcess(vehicleNo: Id, stockmonitorBaseUrl: string, dealerId?: Id, dealerLocationId?: Id): Promise<instantiateReservationProcessResponse> {
		const body = {
			dealerId,
			dealerLocationId,
			vehicleNo,
			stockmonitorBaseUrl
		};
		return this.api.post("reservation-payment/reservation/instantiate", body)
			.then(response => response.data);
	}

	async applyCustomerData(customerData: CustomerData): Promise<any> {
		return this.api.post("reservation-payment/customer", customerData)
			.then(response => response.data);
	}
}
