// @flow
import React from "react";
import image from "../assets/images/sentiment_dissatisfied.svg";
import StepContainer from "../container/StepContainer";
import {Trans, Translation} from "react-i18next";

type CarNotAvailableProps = {
	t: Translation,
	handleBack: () => void,
};

export const CarNotAvailable = (props: CarNotAvailableProps) => {
	return (
		<StepContainer>
			<div className={"CarNotAvailable"}>
				<p><img className={"confirmBanner"} src={image} alt={""}/></p>
				<p className="center"><strong>{props.t("notAvailable.headline")}</strong></p>
				<p className="center">
					<Trans i18nKey="notAvailable.text">
						Your car is already be taken.<br/>
						Please refresh your browser before proceeding shopping.
					</Trans>
				</p>
				<p className="center">
					<button className={"btn notAvailableButton"} onClick={() => props.handleBack()}>{props.t("notAvailable.button")}</button>
				</p>
			</div>
		</StepContainer>
	);
};
