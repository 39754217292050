/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import "./ExpiredModal.scss";
import {Trans} from "react-i18next";
import type {translate} from "../i18n/i18n";

type ExpiredModalProps = {
	baseUrl: string,
	t: translate
};

export const ExpiredModal = (props: ExpiredModalProps) => {
	return <div className={"ExpiredModal"}>
		<div>
			<div>
				<h4 className="center">{props.t("expiredModal.title")}</h4>
				<p className="center">
					<Trans key={"expiredModal.description"}>
						The time granted to complete the reservation has lapsed. The vehicle of your
						choice is available for our other customers again. Please go back to the vehicle page and
						restart
						the reservation process.
					</Trans>
				</p>
				<p className="center">
					<a className="btn" href={props.baseUrl}>{props.t("expiredModal.continue")}</a>
				</p>
			</div>
		</div>
	</div>;
};
