import type {ReducerAction} from "./index";
import {ModuleInitiationExeption} from "../helper/CodeError";
import qs from "query-string";
import type {Id} from "../helper/ReservationPaymentApi";
import {TOGGLE_MODAL} from "../actions/navigation";
import RootNodeProvider from "../helper/RootNodeProvider";

export type ConfigReducerState = {
	language: ?string,
	reservationNo: string,
	cancellationCode: ?string,
	reservationInitial: ?boolean,
	vehicleNo: Id,
	dealerId: Id,
	dealerLocationId: Id,
	baseUrl: string,

	// modes [reservation, vcg, saleNz]
	vcg: boolean,
	saleNz: boolean,
};

const getConfig: () => ConfigReducerState = () => {
	const params = qs.parse(window.location.search);
	const datasets = RootNodeProvider.getRpmRootNode().dataset;

	const reservationInitial = !!datasets.initial;
	const baseUrl = datasets.baseurl || params.baseurl || window.location.origin;
	const vehicleId = datasets.vehicleid || params.vehicleid;
	const lang = datasets.lang || params.lang;
	const reservationCode = datasets.reservationcode || params.reservationcode;
	const cancellationCode = datasets.cancellationcode || params.cancellationcode;
	const dealerId = datasets.dealerid || params.dealerid;
	const dealerLocationId = datasets.dealerlocationid || params.dealerlocationid;
	const vcg = datasets.vcg !== undefined;
	const saleNz = datasets.saleNz !== undefined;

	if (!vehicleId) throw new ModuleInitiationExeption("Missing parameter: vehicleid", 400);
	if (!lang) throw new ModuleInitiationExeption("Missing parameter: lang", 400);
	if (vcg && saleNz) throw new ModuleInitiationExeption("You cannot use both: vcg and sale-nz as operation mode!", 400);

	return {
		language: lang,
		baseUrl,
		reservationInitial,
		vehicleNo: {
			identifier: vehicleId
		},
		dealerId: {
			identifier: dealerId
		},
		dealerLocationId: {
			identifier: dealerLocationId
		},
		reservationNo: reservationCode || null,
		cancellationCode: cancellationCode || null,
		vcg,
		saleNz: saleNz
	};
};

const initialState: ConfigReducerState = getConfig();

export const configReducer = (state: ConfigReducerState = initialState, action: ReducerAction): ConfigReducerState => {
	switch (action.type) {
		case TOGGLE_MODAL.CLOSE:
			return {
				...state,
				reservationInitial: false,
			};
		default:
			return state;
	}
};
