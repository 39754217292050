// @flow
import React from "react";
import image from "../assets/images/sentiment_dissatisfied.svg";
import StepContainer from "../container/StepContainer";
import {Trans, Translation} from "react-i18next";

type PaymentErrorPageProps = {
	t: Translation,
	handleBack: () => void,
};

export const PaymentErrorPage = (props: PaymentErrorPageProps) => {
	return (
		<StepContainer>
			<div>
				<p><img className={"confirmBanner"} src={image} alt={""}/></p>
				<p className="center"><strong>{props.t("payment.cancel.headline")}</strong></p>
				<p className="center">
					<Trans i18nKey="payment.cancel.text">
						We sent you an e-mail to confirm the transaction.<br/>
						Please click on the link in the e-mail within the next 24h to confirm the reservation.<br/>
						Otherwise the reservation will be cancelled and the reservation fee will be fully refunded.
					</Trans>
				</p>
				<p className="center">
					<button className={"btn"} onClick={() => props.handleBack()}>{props.t("payment.cancel.button")}</button>
				</p>
			</div>
		</StepContainer>
	);
};
