// @flow
import {combineReducers} from "redux";
import {configReducer} from "./config";
import {contentReducer} from "./content";
import {customerReducer} from "./customerData";
import type {ConfigReducerState} from "./config";
import type {ContentReducerState} from "./content";
import type {CustomerState} from "./customerData";

export type ApplicationState = {
	config: ConfigReducerState,
	content: ContentReducerState,
	customerData: CustomerState
}

export type ReducerAction = {
	type: string,
	payload: ?any
};

export default combineReducers<ApplicationState, ReducerAction>({
	config: configReducer,
	content: contentReducer,
	customerData: customerReducer
});
