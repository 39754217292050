exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/fonts/Peugeot_New-Light.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/fonts/Peugeot_New-Bold.woff2"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../assets/fonts/Peugeot_New-Regular.woff2"));

// Module
exports.push([module.id, ":root {\n  --primary: rgba(000, 116, 219, 1.00);\n  --secondary: rgba(0, 0, 0, 0, 1.0);\n  --primaryText: rgba(110, 110, 110, 1.00);\n  --primaryTextInverted: rgba(255, 255, 255, 1.00);\n  --secondaryTextInverted: rgba(110, 110, 110, 1.00);\n  --secondaryText: rgba(255, 255, 255, 1.00);\n  --border: rgba(208, 203, 203, 1.00);\n  --alert: rgba(229, 000, 000, 1.0); }\n\n@font-face {\n  font-family: 'sofi-li';\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"); }\n\n@font-face {\n  font-family: 'sofi-bo';\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff2\"); }\n\n@font-face {\n  font-family: 'sofi-ex';\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff2\"); }\n", ""]);

