// @flow
import * as React from "react";
import citroenStyle from "./citroen.lazy.scss";
import dsStyle from "./ds.lazy.scss";
import opelStyle from "./opel.lazy.scss";
import peugeotStyle from "./peugeot.lazy.scss";
import noTheme from "./fallback.lazy.scss";

export type ThemeProps = {
	children?: React.Node,
}

export const NoTheme = (props: ThemeProps) => {
	noTheme.use();
	if (props.children) return props.children;
	return null;
};

export const CitroenTheme = (props: ThemeProps) => {
	citroenStyle.use();
	if (props.children) return props.children;
	return null;
};

export const DSTheme = (props: ThemeProps) => {
	dsStyle.use();
	if (props.children) return props.children;
	return null;
};

export const OpelTheme = (props: ThemeProps) => {
	opelStyle.use();
	if (props.children) return props.children;
	return null;
};

export const PeugeotTheme = (props: ThemeProps) => {
	peugeotStyle.use();
	if (props.children) return props.children;
	return null;
};
