// @flow

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import de from "./translations/de";
import en from "./translations/en";
import are from "./translations/are";
import fr from "./translations/fr";

const resources = {
	en: {translations: en},
	fr: {translations: fr},
	de: {translations: de},
	are: {translation: are}
};

export type translate = string => string;

i18n.use(initReactI18next)
	.init({
		resources: resources,
		fallbackLng: ["en", "fr", "de"],
		debug: true,
		ns: ["translations"],
		defaultNS: "translations",
		interpolation: {
			escapeValue: false,
			formatSeparator: ","
		},
		react: {
			wait: true
		}
	});

export default i18n;
