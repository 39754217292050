// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import {PaymentErrorPage} from "../components/PaymentErrorPage";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		handleBack: () => {
			document.location.reload();
		},
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PaymentErrorPage));
