// @flow

import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {CancelPage} from "../components/CancelPage";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		baseUrl: (state.content.market.stockMonitor && state.content.market.stockMonitor.address)
			? state.content.market.stockMonitor.address
			: state.config.baseUrl,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(CancelPage));
