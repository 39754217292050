// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {VehicleDisplay} from "../components/VehicleDisplay";
import {withTranslation} from "react-i18next";
import currencyConverter from "../helper/currency";

function mapStateToProps(state: ApplicationState, ownProps) {
	const {vehicleDetails} = state.content;

	return {
		imageUrl: vehicleDetails.imageUrl,
		name: vehicleDetails.model,
		price: currencyConverter(vehicleDetails.price, state.config.language, true),
		color: vehicleDetails.externalColor,
		interior: vehicleDetails.internalColor,
		vehicleOptions: vehicleDetails.options,
		dealerDetails: state.content.dealerDetails
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(VehicleDisplay));
