// @flow
import React, {useEffect} from "react";
import image from "../assets/images/mailklicker.svg";
import StepContainer from "../container/StepContainer";
import {Trans, Translation} from "react-i18next";

type ConfirmationPageProps = {
	t: Translation,
	deactivateCounter: () => void,
	expiresInHours: number,
};

export const ConfirmationPage = (props: ConfirmationPageProps) => {
	const {deactivateCounter} = props;
	useEffect(deactivateCounter, []);

	return (
		<StepContainer>
			<div>
				<p><img className={"confirmBanner"} src={image} alt={""}/></p>
				<p className="center">
					<Trans i18nKey="confirmationPage.text" count={props.expiresInHours}>
						We sent you an e-mail to confirm the transaction.<br/>
						Please click on the link in the e-mail within the next {{count: props.expiresInHours}}h to confirm the reservation.<br/>
						Otherwise the reservation will be cancelled and the reservation fee will be fully refunded.
					</Trans>
				</p>
			</div>
		</StepContainer>
	);
};
