// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import Content from "../components/Content";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		isIdle: !state.content.asyncRequestQueue.length,
		carNotAvailable: state.content.carNotAvailable,
		showFinished: !!state.config.reservationNo,
		printBannerUrl: "http://it-tecture.gilmar.de/PSA/PSA-Peugeot/resources/img/dealer-1-detail-desktop_header.jpg",
		reservationState: state.content.reservationState,
		isSale: (state.config.vcg || state.config.saleNz),
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Content);
