// @flow
import React from "react";
import StepContainer from "../container/StepContainer";
import type {Vehicle} from "../model/Vehicle";
import {Translation} from "react-i18next";
import {PriceBox} from "./PriceBox";

type CartPageProps = {
	vehicle: Vehicle,
	handleSubmit: () => void,
	t: Translation,
	name: string,
	vehicleId: string
};

export const CartPage = (props: CartPageProps) => {
	const renderDetails = () => {
		return <div>
			<h4>{props.t("cart.cart")}</h4>
			<table>
				<tbody>
					<tr>
						<td colSpan="2">
							{props.name}
							<br/>
							{props.vehicleId &&
								<small>{props.t("cart.vehicleNumber")} {props.vehicleId}</small>
							}
						</td>
					</tr>
				</tbody>
			</table>
		</div>;
	};

	const renderPriceBox = () => {
		return <div>
			<PriceBox t={props.t}/>
			<button onClick={() => props.handleSubmit()} className={"btn"}>{props.t("priceBox.toCheckout")}</button>
		</div>;
	};

	return (
		<StepContainer>
			{renderDetails()}
			{renderPriceBox()}
		</StepContainer>
	);
};
