import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
import {tracker} from "../helper/tracker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWares = [thunk, tracker];
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleWares)));
export default store;
