// @flow
import React from "react";
import Countdown, {zeroPad} from "react-countdown";
import "./Counter.scss";
import {Translation} from "react-i18next";
import ExpiredModalContainer from "../container/ExpiredModalContainer";

type CounterProps = {
	t: Translation,
	expiresAt: string,
	showCounter: boolean,
};

type ReactCountDownProps = {
	days: string,
	hours: string,
	minutes: string,
	seconds: string,
	completed: boolean,
}

const Counter = (props: CounterProps) => {
	const renderCompleted = () => {
		return <ExpiredModalContainer/>;
	};

	const renderer = ({days, hours, minutes, seconds, completed}: ReactCountDownProps) => {
		if (completed) {
			return renderCompleted();
		}

		if (days) {
			return <span className={"counter"}>
				{props.t("counter.offerAvailable")} <span>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
			</span>;
		}

		if (hours) {
			return <span className={"counter"}>
				{props.t("counter.offerAvailable")} <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
			</span>;
		}

		return <span className={"counter"}>
			{props.t("counter.offerAvailable")} <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
		</span>;
	};
	if (props.expiresAt && props.showCounter) {
		return <Countdown renderer={renderer} date={props.expiresAt}/>;
	}
	return null;
};

export default Counter;
