// @flow
import React from "react";
import "./Stepper.scss";
import {Translation} from "react-i18next";

type StepperProps = {
	t: Translation,
	history: any,
};

export const Stepper = (props: StepperProps) => {
	const getStep = () => {
		const STEP = {
			CART: 1,
			ADDRESS: 2,
			PAYMENT: 3,
			SUMMARY: 4,
			CONFIRMATION: 5
		};

		switch (props.history.location.pathname) {
			case "/address":
				return STEP.ADDRESS;
			case "/payment":
				return STEP.PAYMENT;
			case "/failure":
				return STEP.PAYMENT;
			case "/summary":
				return STEP.SUMMARY;
			case "/confirmation":
				return STEP.CONFIRMATION;
			default:
				return STEP.CART;
		}
	};

	return (
		<div className={`stepper step${getStep()}`}>
			<div className={"stepPoint"}>
				<div>1</div>
				<div>{props.t("system.steps.cart")}</div>
			</div>
			<div className={"stepPoint"}>
				<div>2</div>
				<div>{props.t("system.steps.address")}</div>
			</div>
			<div className={"stepPoint"}>
				<div>3</div>
				<div>{props.t("system.steps.payment")}</div>
			</div>
			<div className={"stepPoint"}>
				<div>4</div>
				<div>{props.t("system.steps.summary")}</div>
			</div>
			<div className={"stepPoint"}>
				<div>5</div>
				<div>{props.t("system.steps.done")}</div>
			</div>
			<div className="stepLine">
				<div/>
			</div>
		</div>
	);
};
