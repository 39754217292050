// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {ReservationModal} from "../components/ReservationModal";
import {closeReservationSuccessModal} from "../actions/navigation";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState, ownProps) {
	return {
		showModal: state.content.showReservationModal && !state.config.cancellationCode,
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {
		closeModal: () => dispatch(closeReservationSuccessModal())
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ReservationModal));
