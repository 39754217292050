// @flow

import type {CustomerData} from "../helper/ReservationPaymentApi";
import ReservationPaymentApi, {AUTHORIZATION_STATE} from "../helper/ReservationPaymentApi";
import type {ApplicationState} from "../reducers";
import {customHistory} from "../helper/history";
import type {AsyncActionTypes, Dispatch} from "./index";
import {getReservationDetails, RESERVATION_STATE} from "./reservation";
import PaymentApi from "../helper/PaymentApi";

type SetAddressActionTypes = {
	FIRSTNAME: string,
	LASTNAME: string,
	PHONENUMBER: string,
	EMAIL: string,
	TNC: string
};

export const SET_ADDRESS: SetAddressActionTypes = {
	FIRSTNAME: "SET_ADDRESS_FIRSTNAME",
	LASTNAME: "SET_ADDRESS_LASTNAME",
	PHONENUMBER: "SET_ADDRESS_PHONENUMBER",
	EMAIL: "SET_ADDRESS_EMAIL",
	TNC: "SET_ADDRESS_TNC"
};

export const INITIATE_PAYMENT = {
	REQUEST: "INITIATE_PAYMENT_REQUEST",
	PENDING: "INITIATE_PAYMENT_PENDING",
	SUCCESS: "INITIATE_PAYMENT_SUCCESS",
	ABORT: "INITIATE_PAYMENT_ABORT",
	FAILURE: "INITIATE_PAYMENT_FAILURE",
	RESET: "INITIATE_PAYMENT_RESET"
};

export const INITIATE_CHECKOUT = {
	REQUEST: "INITIATE_CHECKOUT_REQUEST",
	SUCCESS: "INITIATE_CHECKOUT_SUCCESS",
	SUCCESS_VCG: "INITIATE_CHECKOUT_SUCCESS_VCG",
	SUCCESS_SALE_NZ: "INITIATE_CHECKOUT_SUCCESS_SALE_NZ",
	FAILURE: "INITIATE_CHECKOUT_FAILURE",
	CAR_NOT_AVAILABLE: "INITIATE_CHECKOUT_CAR_NOT_AVAILABLE",
};

export const SEND_CUSTOMER_INFORMATION: AsyncActionTypes = {
	REQUEST: "SEND_CUSTOMER_INFORMATION_REQUEST",
	SUCCESS: "SEND_CUSTOMER_INFORMATION_SUCCESS",
	FAILURE: "SEND_CUSTOMER_INFORMATION_FAILURE"
};

export const COMPLETE_PURCHASE: AsyncActionTypes = {
	REQUEST: "COMPLETE_PURCHASE_REQUEST",
	SUCCESS: "COMPLETE_PURCHASE_SUCCESS",
	FAILURE: "COMPLETE_PURCHASE_FAILURE"
};

export const CANCEL_RESERVATION: AsyncActionTypes = {
	REQUEST: "CANCEL_RESERVATION_REQUEST",
	SUCCESS: "CANCEL_RESERVATION_SUCCESS",
	FAILURE: "CANCEL_RESERVATION_FAILURE"
};

export const CONFIRM_RESERVATION: AsyncActionTypes = {
	REQUEST: "CONFIRM_RESERVATION_REQUEST",
	SUCCESS: "CONFIRM_RESERVATION_SUCCESS",
	FAILURE: "CONFIRM_RESERVATION_FAILURE"
};

export const sendCustomerInformation = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: SEND_CUSTOMER_INFORMATION.REQUEST
		});
		const reservationPaymentApi = new ReservationPaymentApi();
		const customerData: CustomerData = {
			emailAddress: getState().customerData.emailAddress,
			firstname: getState().customerData.firstname,
			lastname: getState().customerData.lastname,
			phoneNumber: getState().customerData.phoneNumber,
			reservationNo: getState().content.reservationNo
		};
		reservationPaymentApi.applyCustomerData(customerData)
			.then(res => {
				dispatch(fetchPaymentFacilities());
			})
			.catch(error => {
				/* eslint-disable-next-line */
				console.log(error);
				if (error.response && error.response.status === 410) {
					dispatch({type: INITIATE_CHECKOUT.CAR_NOT_AVAILABLE});
				} else {
					dispatch({type: SEND_CUSTOMER_INFORMATION.FAILURE});
				}
			});
	};
};

export const fetchPaymentFacilities = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		const paymentFacilitiesNotLoaded = !getState().content.paymentFacilities || getState().content.paymentFacilities.length === 0;
		if (paymentFacilitiesNotLoaded) {
			const paymentApi = new PaymentApi(getState().config.language);
			paymentApi.getPaymentMethods(getState().content.reservationNo.index, getState().content.market.marketNo.index)
				.then(r => {
					dispatch({
						type: SEND_CUSTOMER_INFORMATION.SUCCESS,
						payload: [r.session]
					});
					customHistory.push("/payment");
				})
				.catch(error => {
					/* eslint-disable-next-line */
					console.log(error);

					if (error.response && error.response.status === 410) {
						dispatch({type: INITIATE_CHECKOUT.CAR_NOT_AVAILABLE});
					} else {
						dispatch({type: SEND_CUSTOMER_INFORMATION.FAILURE});
					}
				});
		} else {
			dispatch({
				type: SEND_CUSTOMER_INFORMATION.SUCCESS,
				payload: getState().content.paymentFacilities
			});
			customHistory.push("/payment");
		}
	};
};

export const cancelReservation = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: CANCEL_RESERVATION.REQUEST
		});

		const reservationNo = getState().content.reservationNo.index;
		const cancellationCode = getState().config.cancellationCode;
		const reservationPaymentApi = new ReservationPaymentApi();

		reservationPaymentApi.cancelReservation(reservationNo, cancellationCode)
			.then(res => {
				dispatch({
					type: CANCEL_RESERVATION.SUCCESS,
					payload: RESERVATION_STATE.CANCELED
				});
			})
			.catch(() => {
				dispatch({
					type: CANCEL_RESERVATION.FAILURE,
				});
			});
	};
};

export const confirmReservation = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: CONFIRM_RESERVATION.REQUEST
		});

		const reservationNo = getState().content.reservationNo.index;
		const reservationPaymentApi = new ReservationPaymentApi();

		reservationPaymentApi.confirmReservation(reservationNo)
			.then(res => {
				dispatch({
					type: CONFIRM_RESERVATION.SUCCESS,
					payload: RESERVATION_STATE.CONFIRMED
				});
			})
			.catch(() => {
				dispatch({
					type: CONFIRM_RESERVATION.FAILURE,
				});
			});
	};
};

export const completePurchase = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: COMPLETE_PURCHASE.REQUEST
		});

		const reservationNo = getState().content.reservationNo.index;
		const reservationPaymentApi = new ReservationPaymentApi();

		reservationPaymentApi.approveReservation(reservationNo)
			.then(res => {
				dispatch({
					type: COMPLETE_PURCHASE.SUCCESS,
				});
				customHistory.push("/confirmation");
			})
			.catch(() => {
				dispatch({
					type: COMPLETE_PURCHASE.FAILURE,
				});
			});
	};
};

export const initiatePayment = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: INITIATE_PAYMENT.REQUEST
		});
		const callApi = () => {
			setTimeout(() => {
				const reservationPaymentApi = new ReservationPaymentApi();
				return reservationPaymentApi.pollForPaymentFinished(getState().content.reservationNo.index)
					.then(res => {
						const authorizationState = res.authorizationState;
						switch (authorizationState) {
							case AUTHORIZATION_STATE.authorized : {
								return dispatch({
									type: INITIATE_PAYMENT.SUCCESS,
									payload: authorizationState,
								});
							}
							case AUTHORIZATION_STATE.failed: {
								return dispatch({
									type: INITIATE_PAYMENT.ABORT,
									payload: authorizationState,
								});
							}
							default: {
								return callApi();
							}
						}
					}).catch(e => {
						dispatch({
							type: INITIATE_PAYMENT.FAILURE,
							payload: AUTHORIZATION_STATE.failed
						});
						return callApi();
					});
			}, 1000 * 5);
		};
		callApi();
	};
};

export const initiateCheckout = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: INITIATE_CHECKOUT.REQUEST
		});
		const reservationPaymentApi = new ReservationPaymentApi();

		if (getState().config.saleNz) {
			reservationPaymentApi.instantiateSaleNzProcess(getState().config.vehicleNo, getState().config.baseUrl, getState().config.dealerId, getState().config.dealerLocationId)
				.then(res => {
					dispatch(getReservationDetails(res.reservationNo.index, res.market, res.expiresAt, false, true));
				})
				.catch(error => {
					if (error.response && error.response.status === 410) {
						dispatch({type: INITIATE_CHECKOUT.CAR_NOT_AVAILABLE});
					} else {
						dispatch({type: INITIATE_CHECKOUT.FAILURE});
					}
				});
		} else if (getState().config.vcg) {
			reservationPaymentApi.instantiateVCGSaleProcess(getState().config.vehicleNo, getState().config.baseUrl, getState().config.dealerId, getState().config.dealerLocationId)
				.then(res => {
					dispatch(getReservationDetails(res.reservationNo.index, res.market, res.expiresAt, true, false));
				})
				.catch(error => {
					if (error.response && error.response.status === 410) {
						dispatch({type: INITIATE_CHECKOUT.CAR_NOT_AVAILABLE});
					} else {
						dispatch({type: INITIATE_CHECKOUT.FAILURE});
					}
				});
		} else {
			reservationPaymentApi.instantiateReservationProcess(getState().config.vehicleNo, getState().config.baseUrl, getState().config.dealerId, getState().config.dealerLocationId)
				.then(res => {
					dispatch(getReservationDetails(res.reservationNo.index, res.market, res.expiresAt, false, false));
				})
				.catch(error => {
					if (error.response && error.response.status === 410) {
						dispatch({type: INITIATE_CHECKOUT.CAR_NOT_AVAILABLE});
					} else {
						dispatch({type: INITIATE_CHECKOUT.FAILURE});
					}
				});
		}
	};
};
