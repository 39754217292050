// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import {PriceDisplay} from "../components/PriceDisplay";

function mapStateToProps(state: ApplicationState, ownProps) {
	const priceWithoutOptions = state.content.vehicleDetails.price && state.content.vehicleDetails.sumOptionsPrice
		? {...state.content.vehicleDetails.price, value: state.content.vehicleDetails.price.value - state.content.vehicleDetails.sumOptionsPrice.value}
		: null;
	return {
		price: priceWithoutOptions,
		lang: state.config.language
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return {};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PriceDisplay));
