/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import "./ConfirmCancelModal.scss";
import type {translate} from "../i18n/i18n";
import {Trans} from "react-i18next";

type ConfirmCancelModalProps = {
	showModal: boolean,
	handleCancelReservation: () => void,
	handleAbort: () => void,
	t: translate
};
export const ConfirmCancelModal = (props: ConfirmCancelModalProps) => {
	return props.showModal
		? (
			<div className={"ConfirmCancelModal"}>
				<div>
					<div>
						<h4 className="center">{props.t("confirmCancelModal.title")}</h4>
						<p className="center">
							<Trans i18nKey="confirmCancelModal.description">
                                Do you want to cancel your reservation? The car is then <br/>
                                no longer reserved for you and the reservation fee will be refunded.
							</Trans>
						</p>

						<div className="desktop">
							<a onClick={() => props.handleAbort()}>{props.t("confirmCancelModal.no")}</a>
							<a onClick={() => props.handleCancelReservation()}>{props.t("confirmCancelModal.yes")}</a>
						</div>
					</div>
				</div>
			</div>
		) : null;
};
