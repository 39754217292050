// @flow

import type {ApplicationState} from "../reducers";
import {initiateCheckout} from "./checkout";
import {getReservation} from "./reservation";

export type Action = any;
export type ThunkAction = (dispatch: Dispatch, getState: () => ApplicationState) => any; // eslint-disable-line no-use-before-define
type PromiseAction = Promise<Action>;
export type Dispatch = (action: Action | ThunkAction | PromiseAction) => any;

export type AsyncActionTypes = {
	REQUEST: string,
	SUCCESS: string,
	FAILURE: string,
};

export const initModule = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		if (getState().config.reservationNo) {
			return dispatch(getReservation());
		}
		return dispatch(initiateCheckout());
	};
};
