// @flow
import {connect} from "react-redux";
import App from "../components/App";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import {initModule} from "../actions";

type AppContainerProps = {}

function mapStateToProps(state: ApplicationState, ownProps: AppContainerProps) {
	return {
		configLoaded: state.content.configLoaded,
		manufacturer: state.content.market.manufacturer.name
	};
}

function mapDispatchToProps(dispatch, ownProps: AppContainerProps) {
	return {
		initModule: () => dispatch(initModule())
	};
}

export default withTranslation()(connect<any, any, _, _, _, _>(mapStateToProps, mapDispatchToProps)(App));
