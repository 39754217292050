/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import StepContainer from "../container/StepContainer";
import {Trans, Translation} from "react-i18next";
import "./AddressPage.scss";
import {PriceBox} from "./PriceBox";

type AddressPageProps = {
	firstname: string,
	lastname: string,
	emailAddress: string,
	phoneNumber: string,
	isAddressValid: boolean,
	isTncAccepted: boolean,
	handleSubmit: () => void,
	handleFirstNameChange: string => void,
	handleLastNameChange: string => void,
	handleMailChange: string => void,
	handlePhoneChange: string => void,
	toggleAcceptTnc: () => void,
	toggleTermsAndConditions: () => void,
	togglePrivacyPolicy: () => void,
	t: Translation,
};

export const AddressPage = (props: AddressPageProps) => {
	const renderDetails = () => {
		return <div>
			<h4>{props.t("address.myData")}</h4>
			<table>
				<tbody>
					<tr>
						<td>
							<div className="form-label-group">
								<input id="firstname" value={props.firstname} placeholder=" "
								   onChange={e => props.handleFirstNameChange(e.target.value)} type="text"
								   autoCapitalize="words"/>
								<label htmlFor="firstname">{props.t("address.firstName")} *</label>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="form-label-group">
								<input id="lastname" value={props.lastname} placeholder=" "
								   onChange={e => props.handleLastNameChange(e.target.value)} type="text"
								   autoCapitalize="words"/>
								<label htmlFor="lastname">{props.t("address.lastName")} *</label>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="form-label-group">
								<input id="email" value={props.emailAddress} placeholder=" "
								   onChange={e => props.handleMailChange(e.target.value)} type="email"/>
								<label htmlFor="email">{props.t("address.eMail")} *</label>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="form-label-group">
								<input id="tel" value={props.phoneNumber} placeholder=" "
								   onChange={e => props.handlePhoneChange(e.target.value)}
								   type="tel"/>
								<label htmlFor="tel">{props.t("address.phoneNumber")} *</label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>;
	};

	const renderPriceBox = () => {
		return <div>
			<PriceBox t={props.t}/>
			<table>
				<tbody>
					<tr>
						<td>
							<div className="termsAndConditionsBox">
								<input onClick={() => props.toggleAcceptTnc()} value={props.isTncAccepted ? "on" : ""}
								   className="check" type="checkbox"
								   id="agreement"/>
								<label onClick={() => props.toggleAcceptTnc()} className="termsAndConditions">
									<div>
										<Trans i18nKey="priceBox.consent">
											YES. I consent to the <a onClick={e => {
												e.stopPropagation();
												props.toggleTermsAndConditions();
											}}> Data Privacy
											Policy and the Reservation Terms &
											Conditions </a>.
										</Trans>
									</div>
								</label>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="procedereHint">{props.t("priceBox.youGonnaPayNote")}</div>
						</td>
					</tr>
				</tbody>
			</table>
			<button disabled={!props.isAddressValid} onClick={() => props.handleSubmit()} className={"btn"}>
				{props.t("priceBox.choosePayment")}
			</button>
		</div>;
	};

	return (
		<StepContainer>
			{renderDetails()}
			{renderPriceBox()}
		</StepContainer>
	);
};
