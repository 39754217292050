// @flow
import * as React from "react";
import CarTotalPriceMinusOptionsPriceContainer from "../container/CarTotalPriceMinusOptionsPriceContainer";
import SumOptionsPriceContainer from "../container/SumOptionsPriceContainer";
import CarTotalPriceContainer from "../container/CarTotalPriceContainer";
import ReservationPriceContainer from "../container/ReservationPriceContainer";
import CarTotalPriceMinusReservationFeeContainer from "../container/CarTotalPriceMinusReservationFeeContainer";
import type {translate} from "../i18n/i18n";

type PriceBoxProps = {
	t: translate
}

export const PriceBox = (props: PriceBoxProps) => {
	return <table>
		<tbody>
			<tr>
				<td>{props.t("priceBox.priceWithoutOptions")}</td>
				<td className="right">
					<CarTotalPriceMinusOptionsPriceContainer/>
				</td>
			</tr>
			<tr>
				<td> {props.t("priceBox.optionsPrice")}
					<div><small>{props.t("priceBox.optionsNote")}</small></div>
				</td>
				<td className="right">
					<SumOptionsPriceContainer/>
				</td>
			</tr>
			<tr className="mlo">
				<td> {props.t("priceBox.subTotal")}</td>
				<td className="right">
					<CarTotalPriceContainer/>
				</td>
			</tr>
			<tr>
				<td> {props.t("priceBox.reservationFee")}</td>
				<td className="right">
					<ReservationPriceContainer/>
				</td>
			</tr>
			<tr className="mlo">
				<td><strong>{props.t("priceBox.grandTotal")}</strong></td>
				<td className="right">
					<strong><CarTotalPriceMinusReservationFeeContainer/></strong>
				</td>
			</tr>
		</tbody>
	</table>;
};
