// @flow

import i18n from "../i18n/i18n";

export const toLocaleDate = (date: string, lang: string = i18n.language || "en") => {
	return (new Date(Date.parse(date))).toLocaleDateString(lang);
};

export const toLocaleDateTime = (date: string, lang: string = i18n.language || "en") => {
	const parsed: Date = new Date(Date.parse(date));
	const parsedDate = parsed.toLocaleDateString(lang);
	const parsedTime = parsed.toLocaleTimeString(lang, {hour: "2-digit", minute: "2-digit"});

	return `${parsedDate} - ${parsedTime}`;
};
